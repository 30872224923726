import React, { useState, useEffect } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import MUIDataTable, {
  MUIDataTableColumn, // eslint-disable-line no-unused-vars
} from "mui-datatables";
import Paper from "@material-ui/core/Paper";
import * as t from "io-ts";
import { isRight } from "fp-ts/lib/Either";
import { PathReporter } from "io-ts/lib/PathReporter";
import { format } from "date-fns";

import db, { firestore } from "../../../firebase/db";

type Unpacked<T> = T extends (infer U)[] ? U : T;

const Dancer = t.strict({
  firstName: t.string,
  lastName: t.string,
  email: t.string,
  address: t.union([
    t.null,
    t.type({
      city: t.string,
      number: t.string,
      street: t.string,
      zip: t.string,
    }),
  ]),
  subscribeNewsletter: t.boolean,
});
const Registration = t.strict({
  id: t.string,
  dancer1: Dancer,
  dancer2: t.union([t.null, Dancer]),
  dances: t.strict({
    soloDance: t.boolean,
    ballroom1: t.boolean,
    ballroom2: t.boolean,
    ballroom3: t.boolean,
    ballroom4: t.boolean,
    discoBoogie1: t.boolean,
  }),
  message: t.string,
  createdAt: t.any,
  type: t.keyof({ "cursus-registration-september-2024": null }),
});

interface EnhanceRegistration extends t.TypeOf<typeof Registration> {
  selectedDances: string;
  createdAt: Date;
}

const dances: Record<string, string> = {
  soloDance: "Solo Dance",
  ballroom1: "Ballroom 1",
  ballroom2: "Ballroom 2",
  ballroom3: "Ballroom 3",
  ballroom4: "Ballroom 4",
  discoBoogie1: "Disco boogie 1",
  discoBoogie2: "Disco boogie 2",
};

const DancerDetail = ({ dancer }: { dancer: t.TypeOf<typeof Dancer> }) => (
  <>
    <Grid item xs={2}>
      <Typography variant="body2">Name:</Typography>
    </Grid>
    <Grid item xs={10}>
      <Box fontWeight="fontWeightMedium" clone>
        <Typography variant="body2">
          {dancer.firstName} {dancer.lastName}
        </Typography>
      </Box>
    </Grid>
    <Grid item xs={2}>
      <Typography variant="body2">Address:</Typography>
    </Grid>
    <Grid item xs={10}>
      <Typography variant="body2">
        {dancer.address === null ? (
          <Box fontStyle="italic">NA</Box>
        ) : (
          `${dancer.address.street} ${dancer.address.number} ${dancer.address.zip} ${dancer.address.city}`
        )}
      </Typography>
    </Grid>
    <Grid item xs={2}>
      <Typography variant="body2">Email:</Typography>
    </Grid>
    <Grid item xs={10}>
      <Typography variant="body2">{dancer.email}</Typography>
    </Grid>
    <Grid item xs={12}>
      <FormControlLabel
        disabled
        control={<Checkbox checked={dancer.subscribeNewsletter} />}
        label="Subscribed to newsletter"
      />
    </Grid>
  </>
);

const PendingRequests: React.FC<{ path: string }> = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [registrations, setRegistrations] = useState<EnhanceRegistration[]>([]);
  useEffect(() => {
    if (db === null) {
      return undefined;
    }

    let isSubscribed = true;
    db.collection("registrations")
      .where("rejectedAt", "==", null)
      .where("type", "==", "cursus-registration-september-2024")
      .get()
      .then((snapshot) => {
        if (!isSubscribed) {
          return;
        }
        setRegistrations(
          snapshot.docs
            .map((doc) => {
              const decodedRequest = Registration.decode({
                ...doc.data(),
                id: doc.id,
              });
              if (isRight(decodedRequest)) {
                return decodedRequest.right;
              }
              throw new Error(
                `Problem while decoding registration ${
                  doc.id
                }\n${PathReporter.report(decodedRequest).join("\n")}`
              );
            })
            .map(
              (
                registration: t.TypeOf<typeof Registration>
              ): EnhanceRegistration => {
                const selectedDances: EnhanceRegistration["selectedDances"] = Object.entries(
                  registration.dances
                )
                  .filter((entry) => entry[1])
                  .map(([name]) => name)
                  .join(",");
                return {
                  ...registration,
                  selectedDances,
                  createdAt: registration.createdAt.toDate(),
                };
              }
            )
        );
      })
      .finally(() => {
        if (!isSubscribed) {
          return;
        }
        setIsLoading(false);
      });
    return () => {
      isSubscribed = false;
    };
  }, []);
  const columns: MUIDataTableColumn[] = [
    {
      name: "dancer1.firstName",
      label: "First name",
      options: {
        customBodyRenderLite: (index: number) => {
          const { dancer1, dancer2 } = registrations[index];
          return (
            <>
              <div>{dancer1.firstName}</div>
              {dancer2 !== null && (
                <>
                  <Divider />
                  <div>{dancer2.firstName}</div>
                </>
              )}
            </>
          );
        },
        filter: false,
        sort: true,
      },
    },
    {
      name: "dancer1.lastName",
      label: "Last name",
      options: {
        customBodyRenderLite: (index: number) => {
          const { dancer1, dancer2 } = registrations[index];
          return (
            <>
              <div>{dancer1.lastName}</div>
              {dancer2 !== null && (
                <>
                  <Divider />
                  <div>{dancer2.lastName}</div>
                </>
              )}
            </>
          );
        },
        filter: false,
        sort: true,
      },
    },
    {
      name: "dancer1.email",
      label: "Email",
      options: {
        customBodyRenderLite: (index: number) => {
          const { dancer1, dancer2 } = registrations[index];
          return (
            <>
              <div>{dancer1.email}</div>
              {dancer2 !== null && (
                <>
                  <Divider />
                  <div>{dancer2.email}</div>
                </>
              )}
            </>
          );
        },
        filter: false,
        sort: true,
      },
    },
    {
      name: "dancer1.email",
      label: "Email 1",
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: "dancer2.email",
      label: "Email 2",
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: "createdAt",
      label: "Sent at",
      options: {
        customBodyRenderLite: (index: number) =>
          format(registrations[index].createdAt, "dd/MM/yyyy HH:mm"),
        filter: false,
        sort: true,
      },
    },
    {
      name: "selectedDances",
      label: "Selected lessons",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (index: number) => {
          const { selectedDances } = registrations[index];
          if (selectedDances === "") {
            return "";
          }

          return selectedDances.split(",").map((dance: string) => (
            <React.Fragment key={dance}>
              <Chip size="small" label={dances[dance]} />{" "}
            </React.Fragment>
          ));
        },
        filterType: "multiselect",
        customFilterListOptions: {
          render: (dance: string) => dances[dance],
        },
        filterOptions: {
          names: Object.keys(dances),
          renderValue: (dance: string) => dances[dance],
          logic: (selectedDances: string, filteredDances: string[]) =>
            !selectedDances
              .split(",")
              .some((dance) => filteredDances.includes(dance)),
        },
      },
    },
  ];

  return (
    <TableContainer component={Paper}>
      {isLoading && <LinearProgress />}
      <MUIDataTable
        title=""
        data={isLoading ? [] : registrations}
        options={{
          textLabels: {
            body: {
              noMatch: isLoading
                ? "Loading..."
                : "Sorry, no matching records found",
            },
          },
          enableNestedDataAccess: ".",
          expandableRows: true,
          onRowsDelete: ({ data }: { data: { dataIndex: number }[] }) => {
            data.forEach(({ dataIndex }) => {
              if (db === null) {
                return;
              }
              db.collection("registrations")
                .doc(registrations[dataIndex].id)
                .update({
                  rejectedAt: firestore.FieldValue.serverTimestamp(),
                });
            });
          },
          renderExpandableRow: (
            rowData: string[],
            { dataIndex }: { dataIndex: number }
          ) => {
            const registration = registrations[dataIndex];
            const colSpan = rowData.length + 1;
            return (
              <TableRow>
                <TableCell colSpan={colSpan}>
                  <Grid container>
                    <Grid item xs={4}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Typography variant="subtitle1">Dancer 1</Typography>
                        </Grid>
                        <DancerDetail dancer={registration.dancer1} />
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Typography variant="subtitle1">Dancer 2</Typography>
                        </Grid>
                        {registration.dancer2 === null ? (
                          <Grid item xs={12}>
                            <Typography variant="body2">
                              <Box fontStyle="italic">NA</Box>
                            </Typography>
                          </Grid>
                        ) : (
                          <DancerDetail
                            dancer={{
                              ...registration.dancer2,
                              address:
                                registration.dancer2.address ??
                                registration.dancer1.address,
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Typography variant="subtitle1">Dances</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <FormGroup>
                            {Object.entries(registration.dances).map(
                              ([dance, checked]: [string, boolean]) => (
                                <FormControlLabel
                                  key={dance}
                                  control={
                                    <Checkbox disabled checked={checked} />
                                  }
                                  label={dances[dance]}
                                />
                              )
                            )}
                          </FormGroup>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1">Message</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {registration.message}
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            );
          },
        }}
        columns={columns}
      />
    </TableContainer>
  );
};

export default PendingRequests;
